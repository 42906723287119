import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Text,
  Checkbox,
  VStack,
  Heading,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Event data (replace with your event data)

const itemsPerPage = 6; // Number of items to show per page

const EventComponent = (props) => {
  const { events, ctaHeader, ctaDescription, ctaButtonTitle } = props;
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [stateMenuOpen, setStateMenuOpen] = useState(false);
  const [monthMenuOpen, setMonthMenuOpen] = useState(false);

  // Check if the device is mobile or not
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    // Extract unique states and months from events
    const filteredEvents = events.filter((event) => {
      const eventDate = new Date(event.node.date);
      const eventMonth = eventDate.toLocaleString("default", {
        month: "long",
      });
      const today = new Date();

      // Check if event is today or in the future
      const isFutureEvent = eventDate >= today;

      const isStateMatch =
        selectedStates.length > 0
          ? selectedStates.includes(event.node.state.name)
          : true;
      const isMonthMatch =
        selectedMonths.length > 0 ? selectedMonths.includes(eventMonth) : true;

      return isStateMatch && isMonthMatch && isFutureEvent;
    })
    const states = Array.from(
      new Set(filteredEvents.map((event) => event.node.state.name))
    );
    const months = Array.from(
      new Set(
        filteredEvents.map((event) =>
          new Date(event.node.date).toLocaleString("default", { month: "long" })
        )
      )
    );

    setAvailableStates(states);
    setAvailableMonths(months);
  }, [events]);

  const handleStateChange = (stateName) => {
    if (selectedStates.includes(stateName)) {
      setSelectedStates(selectedStates.filter((state) => state !== stateName));
    } else {
      setSelectedStates([...selectedStates, stateName]);
    }
  };

  const handleMonthChange = (monthName) => {
    if (selectedMonths.includes(monthName)) {
      setSelectedMonths(selectedMonths.filter((month) => month !== monthName));
    } else {
      setSelectedMonths([...selectedMonths, monthName]);
    }
  };

  const filteredEvents = events
    .filter((event) => {
      const eventDate = new Date(event.node.date);
      const eventMonth = eventDate.toLocaleString("default", {
        month: "long",
      });
      const today = new Date();

      // Check if event is today or in the future
      const isFutureEvent = eventDate >= today;

      const isStateMatch =
        selectedStates.length > 0
          ? selectedStates.includes(event.node.state.name)
          : true;
      const isMonthMatch =
        selectedMonths.length > 0 ? selectedMonths.includes(eventMonth) : true;

      return isStateMatch && isMonthMatch && isFutureEvent;
    })
    .sort((a, b) => new Date(a.node.date) - new Date(b.node.date));

  console.log(filteredEvents, "filteredEvents");
  const paginatedEvents = filteredEvents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredEvents.length / itemsPerPage);

  const handleForm = () => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(["openForm", "VusR3G"]);
  };
  return (
    <Box
      display="flex"
      fontFamily={"Poppins"}
      flexDirection={{ base: "column", md: "row" }}
      gap={{ md: "50px" }}
      maxW={{ base: "90%", md: "90%" }}
      margin={{ base: "0 auto" }}
      padding={{ md: "40px" }}
    >
      {/* Sidebar for desktop and dropdowns for mobile */}
      <Box
        w={{ base: "100%", md: "15%" }}
        pt={"24px"}
        borderTop={{ md: "3px solid #231F59" }}
        mr={5}
      >
        <VStack align="start" spacing={4}>
          <Heading
            as="h4"
            fontSize={{ md: "28px" }}
            color={`#231F59`}
            fontWeight={"bold"}
            textTransform={"uppercase"}
            display={{ base: "none", md: "block" }}
          >
            Filter By
          </Heading>

          {isMobile ? (
            <Box
              w={"100%"}
              paddingBottom={"40px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={"20px"}
            >
              <Menu isOpen={stateMenuOpen}>
                <MenuButton
                  width={"100%"}
                  as={Button}
                  onClick={() => setStateMenuOpen(!stateMenuOpen)}
                >
                  Select State{" "}
                  {selectedStates.length > 0 && `(${selectedStates.length})`}
                </MenuButton>
                <MenuList>
                  {availableStates.map((state) => (
                    <MenuItem key={state}>
                      <Checkbox
                        onChange={() => handleStateChange(state)}
                        isChecked={selectedStates.includes(state)}
                        color={"#231F59"}
                      >
                        {state}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <Menu isOpen={monthMenuOpen}>
                <MenuButton
                  width={"100%"}
                  as={Button}
                  onClick={() => setMonthMenuOpen(!monthMenuOpen)}
                >
                  Select Month{" "}
                  {selectedMonths.length > 0 && `(${selectedMonths.length})`}
                </MenuButton>
                <MenuList>
                  {availableMonths.map((month) => (
                    <MenuItem key={month}>
                      <Checkbox
                        onChange={() => handleMonthChange(month)}
                        isChecked={selectedMonths.includes(month)}
                        color={"#231F59"}
                      >
                        {month}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <>
              <Text
                color={"#231F59"}
                fontSize={{ md: "21px" }}
                fontWeight="bold"
              >
                State
              </Text>
              {availableStates.map((state) => (
                <Checkbox
                  key={state}
                  onChange={() => handleStateChange(state)}
                  isChecked={selectedStates.includes(state)}
                  color={"#231F59"}
                >
                  <Text color={"#231F59"} fontSize={{ md: "21px" }}>
                    {state}
                  </Text>
                </Checkbox>
              ))}

              <Text
                color={"#231F59"}
                fontSize={{ md: "21px" }}
                fontWeight="bold"
              >
                Month
              </Text>
              {availableMonths.map((month) => (
                <Checkbox
                  key={month}
                  color={"#231F59"}
                  onChange={() => handleMonthChange(month)}
                  isChecked={selectedMonths.includes(month)}
                >
                  <Text color={"#231F59"} fontSize={{ md: "21px" }}>
                    {month}
                  </Text>
                </Checkbox>
              ))}
            </>
          )}
        </VStack>
      </Box>

      {/* Events Grid */}
      <Box w={{ base: "100%", md: "85%" }}>
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          pb={"60px"}
          gap={10}
        >
          {paginatedEvents.map((event) => {
            // const dateString = "2024-10-16";
            const dateString = event?.node?.date;
            const date = new Date(`${dateString}T00:00:00`); // Forces the date to be at midnight local time
            const formattedDate = date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            });
            return (
              <Box key={event.node._id} overflow="hidden">
                {event?.node?.image?.asset?.gatsbyImageData && (
                  <GatsbyImage
                    style={{ maxHeight: "350px", borderRadius: "16px" }}
                    image={event.node.image.asset.gatsbyImageData}
                    alt={event.node.name}
                  />
                )}
                <Text
                  color={"#231F59"}
                  fontSize={{ base: "28px" }}
                  letterSpacing={"0px"}
                  fontWeight="bold"
                >
                  {event.node.name}
                </Text>
                <Text color={"#231F59"} fontSize={{ base: "21px" }}>
                  {formattedDate}
                </Text>
                <Text
                  color={"#231F59"}
                  fontSize={{ base: "21px" }}
                  borderBottom={"3px solid #231F59"}
                  fontWeight="bold"
                  mb={4}
                  pb={4}
                >
                  {event.node.city}, {event.node.state.name}
                </Text>
                <Text color={"#231F59"} fontSize={{ base: "21px" }} mb={2}>
                  {event.node.description}
                </Text>
                <Link to={event.node.link} target="_blank">
                  <Text
                    color={"#231F59"}
                    _hover={{ background: "#231F59", color: "white" }}
                    transitionDuration={"0.3s"}
                    fontSize={{ base: "15px", md: "21px" }}
                    border={`3px solid #231F59`}
                    textAlign={"center"}
                    borderRadius={"18px"}
                    padding={{ base: "10px 15px", md: "15px 29px" }}
                    fontWeight="bold"
                    mt={4}
                    mb={2}
                  >
                    {event.node.location} | {event.node.time}
                  </Text>
                </Link>
              </Box>
            );
          })}
        </Grid>

        {/* Pagination */}
        <HStack mt={4} justify="center">
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            isDisabled={currentPage === 1}
          >
            Previous
          </Button>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            isDisabled={currentPage === totalPages}
          >
            Next
          </Button>
        </HStack>

        <Box
          mt={10}
          flexDirection={"column"}
          borderRadius={"24px"}
          border={`3px solid #231F59`}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={{ base: "20px", md: "28px" }}
        >
          <Text
            color={"#231F59"}
            fontSize={{ base: "21px", md: "28px" }}
            fontWeight="bold"
            textAlign={"center"}
          >
            {ctaHeader}
          </Text>
          <Text
            color={"#231F59"}
            fontSize={{ base: "21px", md: "28px" }}
            mt={2}
            textAlign={"center"}
          >
            {ctaDescription}
          </Text>

          <Button
            _hover={{
              background: "white",
              color: "#6EC63F",
              borderColor: "#6EC63F",
            }}
            background={"#6EC63F"}
            color={"white"}
            transitionDuration={"0.3s"}
            fontSize={{ base: "15px", md: "21px" }}
            border={`3px solid transparent`}
            textAlign={"center"}
            borderRadius={"18px"}
            padding={{ base: "25px 45px", md: "25px 45px" }}
            fontWeight="bold"
            mt={6}
            mb={2}
            onClick={handleForm}
          >
            {ctaButtonTitle}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EventComponent;
